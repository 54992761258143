<template>
  <div class="privacy-policy-wrapper">
    <div class="privacy-policy">
      <h1>POLICA O PRIVATNOSTI PODATAKA</h1>
      Florenta obrt za proizvodnju, trgovinu i usluge, Krste Hegedušića 57b,
      10360 Sesvete, OIB: 65260266584, ovom Policom o privatnosti podataka
      objašnjava kako se služi osobnim podacima dobivenima putem online obrazaca
      na web stranicama ili newsletter listama, e-mail komunikacijom i ostalom
      poslovnom komunikacijom.
      <h2>Uvodne odredbe</h2>
      Florenta poštuje vašu privatnost te se obvezuje na zaštitu vaših osobnih
      podataka. Prikupljanje i čuvanje podataka izvodi se u skladu s odredbama
      Zakona o zaštiti osobnih podataka.
      <h2>Podaci pokriveni sustavom politike privatnosti</h2>
      Podaci koje od vas možemo tražiti i koji su zaštićeni:
      <ul>
        <li>ime i prezime</li>
        <li>adresa e-pošte</li>
        <li>broj telefona</li>
        <li>radno mjesto</li>
        <li>
          ostali podaci koje navedete, a napomenete da želite da ostanu tajni.
        </li>
      </ul>
      U svako se doba možete odjaviti s naše mailing liste izričitom izjavom,
      nakon čega se Florenta neće koristiti vašim podacima u svrhu promocije,
      već isključivo u svoje interne svrhe, npr. procesuiranje ponuda i narudžbi
      te obradu podataka za statističke i poslovne izvještaje.
      <h2>Svrha dobivenih podataka</h2>
      Osobne podatke koje ste dobrovoljno unijeli putem naših kontakt obrazaca,
      e-mail poruka ili prijavom na newsletter listu, prikupljamo i obrađujemo
      radi održavanja kontakata s kupcima, oglašavanja i obrade narudžbi.
      Međutim, vaše osobne podatke ne prodajemo trećima niti ih
      komercijaliziramo na bilo koji drugi način. Svi podaci koje Florenta od
      vas dobije služe isključivo za:
      <ul>
        <li>što efikasniji odgovor na upit</li>
        <li>pružanje tražene usluge</li>
        <li>
          promociju naših usluga (newsletter) u slučaju da ste potvrdili da
          želite primati takav sadržaj
        </li>
        <li>internu obradu podataka za statističke ili poslovne izvještaje</li>
      </ul>
      Florenta jamči da će se prikupljenim podacima koristiti samo u navedene
      svrhe. Ustupanjem svojih osobnih podataka kroz sve oblike komunikacije
      dajete svoju izričitu suglasnost da vam se Florenta može obratiti u
      navedene svrhe. U slučaju da ne želite da Florenta kontaktira s vama,
      dužni ste o tome izričito pisanim putem izvijestiti Florentu. U svakom
      primljenom newsletteru imate mogućnost odjave s mailing liste.
      <h2>Vrijeme trajanja politike privatnosti</h2>
      U trenutku kada dostavite svoje podatke pristajete na to da s njima
      raspolažemo u prethodno navedene svrhe. Ukoliko želite primati Florenta
      newsletter, potrebno je potvrditi svoju prijavu na našu mailing listu.
      Trenutak ulaska predstavlja vaš izričit pristanak na kontakt. Zaštita je
      privatnosti vaših podataka trajna, a u svakom trenutku možete tražiti
      isključenje s naše mailing liste. Nakon toga se Florenta više neće
      koristiti vašim podacima osim za interne svrhe, npr. radi informatičke ili
      statističke obrade podataka.
      <h2>Sadržaj politike privatnosti</h2>
      Vašu adresu e-pošte i ostale podatke ne prodajemo, ne iznajmljujemo niti
      stavljamo trećim pravnim i fizičkim osobama izvan Florente i povezanih
      društava na raspolaganje bez vašeg pristanka, jer je to protivno našoj
      politici o privatnosti. Florenta ne odgovara za slučajnu pogrešku ili
      pogrešku zbog više sile, ili drugih objektivnih okolnosti kojima se
      uzrokuje slučajno kršenje zajamčene zaštite vaših podataka, ali jamči da
      će pogreška biti otklonjena, ako je to moguće, čim prije.
      <h2>Poveznice na druge internetske stranice</h2>
      Florenta web stranice sadrže linkove na druge internetske stranice. Ove
      napomene o zaštiti podataka odnose se samo na naše internetske stranice.
      Molimo vas da uzmete u obzir odredbe o zaštiti osobnih podataka na
      povezanim stranicama. Za sadržaje trećih koji se preko linkova mogu
      koristiti i koji su posebno označeni, ne preuzimamo odgovornost niti su ti
      sadržaji naše vlasništvo. Za ilegalne, pogrešne ili nepotpune sadržaje te
      za štete koje nastanu korištenjem ili nekorištenjem informacija odgovoran
      je isključivo vlasnik internetske strane s kojom ste povezani.

      <h2>Kolačići i internetske tehnologije</h2>
      Florenta web stranice mogu koristiti „kolačiće“ i druge tehnologije koje
      nam olakšavaju isporučivanje sadržaja i oglašavanje ovisno o vašim
      područjima interesa, zahtjeva i/ili analiziranje karakteristika vaših
      posjeta. Kolačići se ne mogu upotrebljavati za otkrivanje vašeg osobnog
      identiteta. Kad pristupate našim web stranicama, ova informacija Florenta
      poslužiteljima identificira vaš pretraživač. Želite li u bilo kojem
      trenutku izbrisati ili onemogućiti kolačiće na svojem računalu, možete
      ažurirati postavke svojeg internetskog pretraživača.
      <h2>Promjena podataka</h2>
      U svakom nam se trenutku možete obratiti kako biste pregledali svoje
      osobne podatke, ažurirali ih, ispravili ili izbrisali. Do tog se trenutka
      u navedene svrhe koristimo vašim starim podacima.
      <h2>Vaša suglasnost</h2>
      Popunjavajući formulare na ovoj stranici jamčite da su informacije koje
      ste priložili točne, da ste poslovno sposobni i ovlašteni raspolagati
      danim informacijama te da ste u cijelosti suglasni da Florenta vaše
      podatke upotrebljava i prikuplja u skladu sa zakonom i uvjetima ove
      politike privatnosti.
      <h2>Transparentnost</h2>
      Ako odlučimo mijenjati svoju politiku privatnosti, promjene ćemo postaviti
      na ovu stranicu tako da možete neprestano imati uvid u njih.
      <h2>Autorstvo</h2>
      Florenta zadržava sva autorska prava na korištenje fotografijama,
      prezentacijama, tekstom i ostalim objavljenim materijalom, u smislu
      pozitivnih zakonskih propisa u Republici Hrvatskoj. Fotografije,
      prezentacije, skripte, tekstovi i ostali materijal ne smiju se
      objavljivati, prodavati, javno ili privatno publicirati niti na bilo koji
      drugi način upotrebljavati bez suglasnosti Florente. Svako nepridržavanje
      uvjeta iz prethodnih stavaka ovog članka povlači za sobom odgovornost i
      obvezu naknade štete.

      <h2>Kontakt</h2>
      Ako želite saznati više informacija o Florenta procesu skupljanja, obrade
      i korištenja podataka ili ih želite izmijeniti ili izbrisati,
      kontaktirajte florenta.buketi@gmail.com
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from "@/components/desktopComponents/FooterComponent.vue";
export default {
  name: "PrivacyPolicyComponent",
  components: { FooterComponent },
};
</script>

<style scoped>
.privacy-policy-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("https://florenta.s3.eu-central-1.amazonaws.com/flo3cover.webp");
  background-size: cover;
}

.privacy-policy {
  width: 50%;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px;
  background: rgba(255, 255, 255, 0.9);
  margin: 10px 10px 0;
}

.footer-component {
  width: 50%;
  margin-top: 0;
}

@media only screen and (max-width: 600px) {
  .privacy-policy {
    width: 100%;
    margin: 0;
  }
  .footer-component {
    width: 100%;
    margin: 0;
  }
}
</style>
