<template>
  <div class="advantages-component">
    <section class="advantages-image">
      <img
        style="max-width: 100%"
        :src="'https://florenta.s3.eu-central-1.amazonaws.com/flo17.webp'"
        alt=""
      />
    </section>
    <section class="advantages-list">
      <AdvantagesListComponent />
    </section>
  </div>
</template>

<script>
import AdvantagesListComponent from "./AdvantagesListComponent.vue";
export default {
  name: "AdvantagesComponent",
  components: { AdvantagesListComponent },
};
</script>

<style scoped>
.advantages-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin: 10px;
  background: #cc93a5;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.advantages-list {
  width: 50%;
  font-family: "Roboto", sans-serif;
  padding: 10px 10px;
  margin-right: 3%;
}

.advantages-image {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}
</style>
