<template>
  <Card style="background: #cc93a5; margin-bottom: 8px">
    <template #title class="card-title"
      >Javite nam se za gratis period od 14 dana!</template
    >
    <template #content>
      <ContactUsFormComponent />
    </template>
  </Card>
</template>

<script>
import ContactUsFormComponent from "./ContactUsFormComponent.vue";
import Card from "primevue/card";
export default {
  name: "ContactUsComponent",
  components: { ContactUsFormComponent, Card },
};
</script>

<style scoped>
div > * {
  text-align: center;
}
</style>
