<script>
import HeroComponent from "./HeroComponent.vue";
import WhatWeDoComponent from "./WhatWeDoComponent.vue";
// import AboutUsComponent from "./AboutUsComponent.vue";
import AdvantagesComponent from "./AdvantagesComponent.vue";
import GaleriaComponent from "./GaleriaComponent.vue";
import ContactUsComponent from "./ContactUsComponent.vue";
import FooterComponent from "./FooterComponent.vue";
import Button from "primevue/button";

export default {
  data() {
    return {
      stickyTopBorder: 0,
      // stickyBottomBorder: 0,
      contactUsButton: {},
      contactUsComponent: {},
      showOffer: true,
    };
  },
  components: {
    GaleriaComponent,
    AdvantagesComponent,
    // AboutUsComponent,
    WhatWeDoComponent,
    HeroComponent,
    ContactUsComponent,
    FooterComponent,
    Button,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.stickyTopBorder =
      (document.getElementById("hero-component").offsetHeight * 2) / 3;
    this.contactUsComponent = document.getElementById("contact-us-component");
    const body = document.body;
    const html = document.documentElement;

    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    // this.stickyBottomBorder = height
    this.contactUsButton = document.getElementById("contact-us-button");
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      if (window.pageYOffset > this.stickyTopBorder) {
        this.contactUsButton?.classList?.add("sticky");
      } else {
        this.contactUsButton?.classList?.remove("sticky");
      }
    },
    scrollToContactUs() {
      this.contactUsComponent.scrollIntoView({ behavior: "smooth" });
      this.showOffer = false;
    },
  },
};
</script>

<template>
  <div>
    <Button
      v-if="this.showOffer"
      id="contact-us-button"
      class="p-button-raised contact-us-button"
      @click="scrollToContactUs"
      >Javite se za gratis period!</Button
    >
    <HeroComponent id="hero-component" />
    <WhatWeDoComponent />
    <!--    <AboutUsComponent />-->
    <AdvantagesComponent />
    <GaleriaComponent />
    <ContactUsComponent id="contact-us-component" />
    <FooterComponent id="footer-component" />
  </div>
</template>

<style scoped>
.contact-us-button {
  display: none;
}

.sticky {
  position: fixed;
  top: 10px;
  right: 30px;
  visibility: visible !important;
  display: block;
}
</style>
