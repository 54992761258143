<template>
  <div class="contact-us-component">
    <section class="contact-us-text">
      <h1 style="font-size: 3rem">
        Javite nam se za gratis period od 14 dana!
      </h1>
      <ContactUsFormComponent />
    </section>
    <section class="contact-us-image">
      <img
        style="max-width: 100%; height: auto"
        :src="'https://florenta.s3.eu-central-1.amazonaws.com/flo4.webp'"
        alt=""
      />
    </section>
  </div>
</template>

<script>
import ContactUsFormComponent from "./ContactUsFormComponent.vue";
export default {
  name: "ContactUsComponent",
  components: { ContactUsFormComponent },
};
</script>

<style scoped>
.contact-us-component {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  background: #cc93a5;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.contact-us-text {
  width: 50%;
  font-family: "Roboto", sans-serif;
  padding: 10px 10px;
  margin-right: 3%;
}

.contact-us-image {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}
</style>
