<template>
  <div v-if="!formSubmitted" class="contact-us-form-component">
    <p style="font-size: 20px">
      Ostavite nam svoje kontakt podatke ili nam se izravno obratite telefonom
      ili mailom.
    </p>
    <div class="form-row-1">
      <InputText
        class="input-text"
        id="first-name"
        type="text"
        placeholder="Ime"
        v-model="firstName"
      />
      <InputText
        class="input-text"
        id="last-name"
        type="text"
        placeholder="Prezime"
        v-model="lastName"
      />
    </div>
    <div class="form-row-2">
      <InputText
        class="input-text"
        id="email"
        type="text"
        placeholder="Email"
        v-model="email"
      />
      <InputText
        class="input-text"
        id="phone-number"
        type="text"
        placeholder="Telefon"
        v-model="phoneNumber"
      />
    </div>
    <Button
      class="p-button-raised"
      v-show="showButton()"
      label="Pošalji"
      @click="sendContactUsForm()"
      :loading="isLoading"
    ></Button>
  </div>
  <div v-else>Hvala na interesu, javit ćemo Vam se prvom prilikom!</div>
</template>

<script>
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import axios from "axios";

export default {
  name: "ContactUsFormComponent",
  components: { InputText, Button },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      isLoading: false,
      formSubmitted: false,
    };
  },
  methods: {
    showButton() {
      return (
        this.firstName && this.lastName && (this.email || this.phoneNumber)
      );
    },
    sendContactUsForm() {
      this.isLoading = true;
      axios
        .post(
          "api/send-contact-us-mail",
          {
            contactUsForm: {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              phoneNumber: this.phoneNumber,
            },
          },
          {
            headers: {
              "X-CSRFToken": document.getElementsByName(
                "csrfmiddlewaretoken"
              )[0].value,
            },
          }
        )
        .then((response) => {
          this.firstName = "";
          this.lastName = "";
          this.email = "";
          this.phoneNumber = "";
          this.formSubmitted = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.contact-us-form-component {
  display: flex;
  flex-direction: column;
}
.form-row-1,
.form-row-2 {
  display: flex;
  flex-direction: row;
}

.input-text {
  margin: 3px;
  width: 100%;
}

.p-button-raised {
  margin: 3px;
}
</style>
