<template>
  <div class="advantage-item-component">
    <img :src="imageSrc" alt="" style="width: 10vh" />
    <div
      style="
        margin-left: 15px;
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      "
    >
      <b style="margin-bottom: -15px">{{ this.title }}</b>
      <p>{{ this.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvantageItemComponent",
  props: {
    imageSrc: "",
    title: "",
    text: "",
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.advantage-item-component {
  display: flex;
  flex-direction: row;
  font-family: "Roboto", sans-serif;
  align-items: center;
}
</style>
