<template>
  <div class="footer-component">
    <div class="footer-component-info-line">
      <i class="pi pi-user footer-component-icon"></i><b>vl. Tomislav Šabić</b>
    </div>
    <div class="footer-component-info-line">
      <i class="pi pi-phone footer-component-icon" /><b>+385 91 1445 441</b>
    </div>
    <div class="footer-component-info-line">
      <i class="pi pi-envelope footer-component-icon" /><b
        >florenta.buketi@gmail.com</b
      >
    </div>
    <div
      class="footer-component-info-line"
      style="cursor: pointer"
      @click="openInstagram()"
    >
      <i class="pi pi-instagram footer-component-icon" /><b>florenta_buketi</b>
    </div>
    <b
      @click="this.$router.push({ name: 'privacyPolicy' })"
      style="cursor: pointer; margin-top: 10px"
      >Pravila privatnosti</b
    >

    <b style="margin-top: 15px; margin-bottom: -15px">© 2022 Florenta</b>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  methods: {
    openInstagram() {
      window.open("https://www.instagram.com/florenta_buketi/");
    },
  },
};
</script>

<style scoped>
.footer-component {
  background: #111111;
  color: white;
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.footer-component-info-line {
  margin-top: 3px;
}

.footer-component-icon {
  color: white;
  margin-right: 3px;
}
</style>
