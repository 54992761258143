import { createRouter, createWebHistory } from "vue-router";
import HomePageComponent from "../components/HomePageComponent.vue";
import PrivacyPolicyComponent from "../components/PrivacyPolicyComponent.vue";

const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomePageComponent,
    },
    {
      path: "/privacy-policy",
      name: "privacyPolicy",
      component: PrivacyPolicyComponent,
    },
  ],
});

export default router;
