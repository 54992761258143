<template>
  <div class="hero-component grid">
    <div class="image-wrapper">
      <img
        :src="'https://florenta.s3.eu-central-1.amazonaws.com/logo.png'"
        alt=""
        style="width: 100%"
      />
      <p
        style="
          text-align: center;
          color: #e973b0;
          margin-bottom: 0;
          margin-top: -5px;
        "
      >
        Najam cvjetnih aranžmana
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroComponent",
};
</script>

<style scoped>
.hero-component {
  width: 100%;
  height: 98.7vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://florenta.s3.eu-central-1.amazonaws.com/flo3cover.webp");
  background-size: cover;
  margin-bottom: 8px;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 3px;
}
.image-wrapper {
  background: white;
  border: 5px black solid;
  padding: 20px 20px;
  width: 80vw;
  max-width: 400px;
}
</style>
