<template>
  <div class="hero-component grid">
    <div class="image-wrapper">
      <Image
        :src="'https://florenta.s3.eu-central-1.amazonaws.com/logo.png'"
        alt="Image"
        width="300"
      />
      <p
        style="
          text-align: center;
          line-height: 1px;
          color: #e973b0;
          margin-bottom: 0;
          margin-top: -5px;
        "
      >
        Najam cvjetnih aranžmana
      </p>
    </div>
  </div>
</template>

<script>
import Image from "primevue/image";
export default {
  name: "HeroComponent",
  components: {
    Image,
  },
};
</script>

<style scoped>
.hero-component {
  height: 98vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("https://florenta.s3.eu-central-1.amazonaws.com/flo1cover.webp");
  background-size: cover;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.image-wrapper {
  background: white;
  border: 5px black solid;
  padding: 20px 20px;
}
</style>
