<template>
  <div>
    <AdvantageItemComponent
      class="advantage-item"
      :image-src="advantage.imageSrc"
      :text="advantage.text"
      :title="advantage.title"
      :key="advantage.title"
      v-for="advantage in advantages"
    />
  </div>
</template>

<script>
import AdvantageItemComponent from "./AdvantageItemComponent.vue";
export default {
  name: "AdvantagesListComponent",
  components: {
    AdvantageItemComponent,
  },
  data() {
    return {
      advantages: [
        {
          imageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/discount.png",
          title: "Cijena",
          text:
            "Kad bismo stavili na papir koliko novca je potrebno da bismo imali aranžman od svježeg cvijeća" +
            " i kad bismo ga mijenjali čim je vidljivo da vene i opadaju mu latice, iznos bi bio astronomski." +
            " Naši aranžmani cjenovno su neusporedivo prihvatljiviji uz besplatnu dostavu.",
        },
        {
          imageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/bouquet.png",
          title: "Izgled",
          text: "Bez obzira koliko dugo stoji, aranžman će uvijek izgledati lijepo i svježe.",
        },
        {
          imageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/sustainability.png",
          title: "Održavanje",
          text: "Naši aranžmani ne zahtijevaju nikakvo održavanje niti temperaturno-svjetlosni režim. I manipulacija je jednostavna: ne morate biti posebno oprezni prilikom premještanja jer se npr. neće izliti voda ili slomiti cvijet.",
        },
        {
          imageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flexibility.png",
          title: "Fleksibilnost",
          text: "Izradit ćemo i ispostaviti aranžman koji zadovoljava i poseban zahtjev klijenta, da li je to veći ili manji aranžman, prevladavajuća boja ili cvijeće, kao što možemo dogovoriti i drugačiju dinamiku isporuke od uobičajene.",
        },
        {
          imageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/no-bugs.png",
          title: "Sigurnost",
          text: "Ne izaziva alergije niti privlači kukce.",
        },
        {
          imageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/documents.png",
          title: "Bez ugovorne obveze",
          text: "Ne inzistiramo na ugovoru, dakle u svakom trenutku možete raskinuti suradnju. Na nama je da kvaliteta usluge bude takva da do toga ne dođe.",
        },
        {
          imageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/support.png",
          title: "Lojalnost",
          text: "Nagrađujemo trajanje suradnje, opseg poslovanja, zasluge pri akviziciji novih klijenata itd.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.title {
  font-size: 3rem;
}
.advantage-item {
  margin-top: 15px;
}
</style>
