<template>
  <Card style="background: #cc93a5; margin-bottom: 8px">
    <template #header>
      <img
        alt="user header"
        :src="'https://florenta.s3.eu-central-1.amazonaws.com/flo17.webp'"
      />
    </template>
    <template #title class="card-title"> Prednosti Naših Aranžmana </template>
    <template #content>
      <AdvantagesListComponent style="font-size: 18px; margin-top: -5px" />
    </template>
  </Card>
</template>

<script>
import AdvantagesListComponent from "./AdvantagesListComponent.vue";
import Card from "primevue/card";
export default {
  name: "AdvantagesComponent",
  components: { AdvantagesListComponent, Card },
};
</script>

<style scoped>
div > * {
  text-align: center;
}
</style>
