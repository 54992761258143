<template>
  <Card style="background: #111111; color: white; margin-bottom: 8px">
    <template #content>
      <div class="footer-component">
        <div class="footer-component-info-line">
          <i class="pi pi-user footer-component-icon"></i
          ><b>vl. Tomislav Šabić</b>
        </div>
        <div class="footer-component-info-line">
          <i class="pi pi-phone footer-component-icon" /><b>+385 91 1445 441</b>
        </div>
        <div class="footer-component-info-line">
          <i class="pi pi-envelope footer-component-icon" /><b
            >florenta.buketi@gmail.com</b
          >
        </div>
        <div
          class="footer-component-info-line"
          style="cursor: pointer"
          @click="openInstagram()"
        >
          <i class="pi pi-instagram footer-component-icon" /><b
            >florenta_buketi</b
          >
        </div>
        <b
          @click="this.$router.push({ name: 'privacyPolicy' })"
          style="cursor: pointer"
          >Pravila privatnosti</b
        >
        <b style="margin-top: 15px; margin-bottom: -15px">© 2022 Florenta</b>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";
export default {
  name: "FooterComponent",
  components: {
    Card,
  },
  methods: {
    openInstagram() {
      window.open("https://www.instagram.com/florenta_buketi/");
    },
  },
};
</script>

<style scoped>
.footer-component {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer-component-info-line {
  margin-top: 3px;
}

.footer-component-icon {
  color: white;
  margin-right: 3px;
}
</style>
