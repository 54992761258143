<template>
  <div v-if="this.showMessage" class="privacy-policy-message-component">
    <div>
      Koristenjem stranice slažete se s našim
      <a
        @click="this.$router.push({ name: 'privacyPolicy' })"
        style="color: #0b7ad1; cursor: pointer"
        >pravilima o privatnosti</a
      >.
    </div>
    <Button class="p-button-success" @click="this.showMessage = false"
      >Ok</Button
    >
  </div>
</template>

<script>
import FooterComponent from "@/components/desktopComponents/FooterComponent.vue";
import Button from "primevue/button";
export default {
  name: "PrivacyPolicyMessageComponent",
  components: { FooterComponent, Button },
  data() {
    return {
      showMessage: false,
    };
  },
  mounted() {
    setTimeout(() => (this.showMessage = true), 5000);
  },
};
</script>

<style scoped>
.privacy-policy-message-component {
  position: fixed;
  bottom: 0;
  visibility: visible !important;
  display: flex;
  width: initial;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  justify-content: space-between;
  align-items: center;
  left: 10px;
  right: 10px;
  padding: 10px;
}
@media only screen and (max-width: 991px) {
  .privacy-policy-message-component {
    left: 0;
    right: 0;
    width: 100%;
  }
}
</style>
