<template>
  <Card style="background: #cc93a5; margin-bottom: 8px">
    <template #header>
      <img
        alt="user header"
        :src="'https://florenta.s3.eu-central-1.amazonaws.com/flo2.webp'"
      />
    </template>
    <template #title> Naša djelatnost </template>
    <template #content>
      <p style="font-size: 18px; margin-top: -5px">
        Izrađujemo i iznajmljujemo živopisne i realistične cvjetne aranžmane sa
        svrhom uljepšavanja poslovnog prostora i vizualnog podizanja imidža
        našeg klijenta. Poznato je da cvijeće pozitivno utječe na vizualni
        doživljaj prostora i ljudsko raspoloženje. Mi smo se potrudili da naši
        aranžmani izgledaju upravo tako: da ispune prostor vedrim bojama i da ih
        se spontano primijeti. Poslovni prostori često su sivi i jednolični a to
        ne mora biti tako.
        <br />
        <br />
        Naši aranžmani raznih boja i veličina prikladni su za urede, kuće,
        stanove, apartmane, razne salone, ordinacije, recepcije, konferencijske
        sale, sobe za sastanke itd.
        <br />
        <br />
        Klijentu najkasnije jednom mjesečno (po dogovoru i češće) zamijenimo
        postojeći aranžman (ili više njih) novim. Bez obzira na trajanje
        suradnje klijent neće dobiti isti aranžman dvaput osim ako to izričito
        zatraži.
      </p>
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";
export default {
  name: "WhatWeDoComponent",
  components: { Card },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
</style>
