<template>
  <div class="what-we-do-component">
    <section class="what-we-do-text">
      <h1 style="font-size: 3rem">Naša djelatnost</h1>
      <p style="font-size: 20px">
        Izrađujemo i iznajmljujemo živopisne i realistične cvjetne aranžmane sa
        svrhom uljepšavanja poslovnog prostora i vizualnog podizanja imidža
        našeg klijenta. Poznato je da cvijeće pozitivno utječe na vizualni
        doživljaj prostora i ljudsko raspoloženje. Mi smo se potrudili da naši
        aranžmani izgledaju upravo tako: da ispune prostor vedrim bojama i da ih
        se spontano primijeti. Poslovni prostori često su sivi i jednolični a to
        ne mora biti tako.
        <br />
        <br />
        Naši aranžmani raznih boja i veličina prikladni su za urede, kuće,
        stanove, apartmane, razne salone, ordinacije, recepcije, konferencijske
        sale, sobe za sastanke itd.
        <br />
        <br />
        Klijentu najkasnije jednom mjesečno (po dogovoru i češće) zamijenimo
        postojeći aranžman (ili više njih) novim. Bez obzira na trajanje
        suradnje klijent neće dobiti isti aranžman dvaput osim ako to izričito
        zatraži.
      </p>
    </section>
    <section class="what-we-do-image">
      <img
        style="max-width: 100%; height: auto"
        :src="'https://florenta.s3.eu-central-1.amazonaws.com/flo2.webp'"
        alt=""
      />
    </section>
  </div>
</template>

<script>
export default {
  name: "WhatWeDoComponent",
  components: {},
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.what-we-do-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  background: #cc93a5;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.what-we-do-text {
  width: 50%;
  font-family: "Roboto", sans-serif;
  padding: 10px 10px;
  margin-left: 3%;
}

.what-we-do-image {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
</style>
