<template>
  <div class="galleria-component">
    <h3 class="title">Galerija</h3>
    <Galleria
      :value="images"
      v-model:activeIndex="activeIndex"
      :responsiveOptions="responsiveOptions"
      :numVisible="7"
      containerStyle="max-width: 850px;"
      :circular="true"
      :fullScreen="true"
      :showItemNavigators="true"
      :showThumbnails="true"
      v-model:visible="displayCustom"
    >
      <template #item="slotProps">
        <img
          :src="slotProps.item.itemImageSrc"
          :alt="slotProps.item.alt"
          style="width: auto; max-height: 800px; display: block"
        />
      </template>
      <template #thumbnail="slotProps">
        <div class="image-wrapper-2">
          <img
            :src="slotProps.item.thumbnailImageSrc"
            :alt="slotProps.item.alt"
          />
        </div>
      </template>
    </Galleria>

    <div
      v-if="images"
      class="grid"
      style="
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      "
    >
      <div
        v-for="(image, index) of images"
        class="image-wrapper"
        :key="index"
        style="margin: 10px"
      >
        <img
          :src="image.thumbnailImageSrc"
          :alt="image.alt"
          @click="imageClick(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Galleria from "primevue/galleria";
export default {
  name: "GaleriaComponent",
  components: { Galleria },
  data() {
    return {
      images: [
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo1.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo1.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo2.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo2.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo3.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo3.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo4.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo4.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo5.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo5.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo6.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo6.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo7.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo7.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo8.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo8.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo9.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo9.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo10.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo10.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo11.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo11.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo12.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo12.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo13.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo13.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo14.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo14.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo15.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo15.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo16.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo16.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo17.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo17.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo18.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo18.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo19.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo19.webp",
          alt: "",
        },
        {
          itemImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo20.webp",
          thumbnailImageSrc:
            "https://florenta.s3.eu-central-1.amazonaws.com/flo20.webp",
          alt: "",
        },
      ],
      activeIndex: 0,
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      responsiveOptions2: [
        {
          breakpoint: "1500px",
          numVisible: 5,
        },
        {
          breakpoint: "1024px",
          numVisible: 3,
        },
        {
          breakpoint: "768px",
          numVisible: 2,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      displayBasic: false,
      displayBasic2: false,
      displayCustom: false,
    };
  },
  methods: {
    imageClick(index) {
      this.activeIndex = index;
      this.displayCustom = true;
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 3rem;
}
.galleria-component {
  background: #cc93a5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.image-wrapper {
  margin: 10px;
  height: 400px;
  width: 400px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper img {
  cursor: pointer;
  max-height: 400px;
  max-width: 400px;
}

.image-wrapper-2 {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
}

.image-wrapper-2 img {
  cursor: pointer;
  max-height: 100px;
  max-width: 100px;
}
</style>
