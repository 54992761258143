<template>
  <div class="advantage-item-component">
    <img :src="imageSrc" alt="" style="width: 10vh" />

    <b style="">{{ this.title }}</b>
    <p style="text-align: center">{{ this.text }}</p>
  </div>
</template>

<script>
export default {
  name: "AdvantageItemComponent",
  props: {
    imageSrc: "",
    title: "",
    text: "",
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.advantage-item-component {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  align-items: center;
  justify-content: center;
}
</style>
