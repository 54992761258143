<script>
import DesktopHomePageComponent from "./desktopComponents/HomePageComponent.vue";
import MobileHomePageComponent from "./mobileComponents/HomePageComponent.vue";
import { MqResponsive } from "vue3-mq";

export default {
  components: {
    DesktopHomePageComponent,
    MobileHomePageComponent,
    MqResponsive,
  },
};
</script>

<template>
  <div>
    <MqResponsive target="lg+">
      <DesktopHomePageComponent />
    </MqResponsive>
    <MqResponsive target="md-">
      <MobileHomePageComponent />
    </MqResponsive>
  </div>
</template>
